import { apiUrl, httpClient } from '../config';
import { PLATFORMS, REGENERATE_STATUSES } from '../config/constants/reportConstants';

const getAccountNameById = (existingData, selectedAccountId) => {
  return existingData.find(acc => acc.id === +selectedAccountId)?.account_name;
};

const checkIfUploadedBefore = async (file) => {
  const response = await httpClient('/analytics/upload_report/isUploadedBefore',
    {
      method: 'POST',
      body: JSON.stringify({ fileName: file.name }),
    }
  );
  const { isUploadedBefore } = JSON.parse(response.body);
  return isUploadedBefore;
};

const getConfirmationMessage = async ({
  platform,
  existingData,
  selectedAccountId,
  file,
}) => {
  const isUploadedBefore = await checkIfUploadedBefore(file);

  if (isUploadedBefore) {
    return `This report has been already uploaded today. Do you want to upload it again?`;
  } else {
    return  `Chosen data is\n` +
    `Platform: ${platform}\n` +
    `${selectedAccountId && existingData ?
        `Account name: ${getAccountNameById(existingData, selectedAccountId)}\n` : ''}` +
    `Filename: ${file.name}\n` +
    `Do you confirm?`
  }
};

const uploadReport = async ({ platform, existingData, accountId, file, target }) => {
  target.textContent = 'In progress';
  target.setAttribute('disabled', 'true');

  const formData = new FormData();
  formData.append('platform', platform);
  if (existingData && accountId) {
    formData.append('accountName', getAccountNameById(existingData, accountId));
  }
  formData.append('report', file);

  return await httpClient(`${apiUrl}/analytics/upload_report`, {
    method: 'POST',
    body: formData,
  });
};

export const processMetaReport = async ({
  metaAccountId,
  existingMetaAccounts,
  file,
  target
}) => {
  if (!file?.name) {
    alert('Please choose a related file.');
    return;
  }

  const confirmationMessage = await getConfirmationMessage({
    platform: PLATFORMS.META,
    existingData: existingMetaAccounts,
    selectedAccountId: metaAccountId,
    file
  });

  return window.confirm(confirmationMessage)  && await uploadReport({
    platform: PLATFORMS.META,
    existingData: existingMetaAccounts,
    accountId: metaAccountId,
    file,
    target
  });
};

export const processAppsFlyerReport = async ({
  file,
  target
}) => {
  if (!file?.name) {
    alert('Please choose a related file.');
    return;
  }

  const confirmationMessage = await getConfirmationMessage({
    platform: PLATFORMS.APPS_FLYER,
    file
  });

  return window.confirm(confirmationMessage)  && await uploadReport({
    platform: PLATFORMS.APPS_FLYER,
    file,
    target
  });
};

export const resetTargetButton = (target, text = 'Submit', isEnabled = true) => {
  target.textContent = text;
  if (isEnabled) {
    target.removeAttribute('disabled');
  } else {
    target.setAttribute('disabled', 'true');
  }
};

export const regenerateReport = async ({
  event,
  setIsSuccess,
  reportToRegenerateId,
  setReportToRegenerateId,
  selectRef,
  status
}) => {
  try {
    event.preventDefault();
    setIsSuccess(null);
    const response = await httpClient(`${apiUrl}/analytics/report_actions/update_report_status`, {
      method: 'POST',
      body: JSON.stringify({ reportToRegenerateId, status }),
    });

    const { status: responseStatus } = JSON.parse(response.body);

    setIsSuccess(responseStatus === 1 ? true : false);
    selectRef.current.value = '';
    setReportToRegenerateId(null);
  } catch (err) {
    console.log(err);
  }
};

export const getStatusInfo = (status) => {
  switch (status) {
    case REGENERATE_STATUSES.UPDATE_ENTIRE_REPORT:
    case REGENERATE_STATUSES.UPDATE_7_DAYS:
      return {
        label: 'Waiting',
        message: ' | Will be regenerated soon',
      };
    case REGENERATE_STATUSES.IN_PROGRESS:
      return {
        label: 'Updating',
        message: ' | In process now...',
      };
    default:
      return {
        label: null,
        message: '',
      };
  }
};

import { useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import { REGENERATE_STATUS_LABELS, REPORT_TYPES } from '../../../../../config/constants/reportConstants';
import { AutocompleteArrayInput, DateInput, SelectArrayInput, SelectInput, TextInput } from 'react-admin';
import { setChoices, timezones } from '../../../../../helpers';
import { CustomPaper } from '../../../../helperComponents/CustomPaper';
import { reportTypesMapToPlatform } from '../../../../../helpers/constants';


export const ReportTypeDependentFieldsCreate = ({
  accounts,
  affiliates,
  uniqueGoals,
  uniqueMediaSources,
  uniqueEventNames,
}) => {
    const { watch } = useFormContext()
    const reportType = watch('report_type')

    return (
        <>
            {reportType === REPORT_TYPES.META_MEDIA_APPS_FLYER_MASTER ? (
                <TextInput source="timezone" label="Timezone" defaultValue="GMT+2" disabled />
            ) : (
                <SelectInput
                    source="timezone"
                    label="Timezone"
                    choices={setChoices(timezones)}
                    required
                    emptyText="Choose timezone"
                />
            )}

            {reportType === REPORT_TYPES.META_MEDIA_APPS_FLYER_MASTER ? (
                <AutocompleteArrayInput
                    label="AppsFlyer Media Source"
                    source="media_sources"
                    choices={uniqueMediaSources.map(mediaSource => ({
                        id: mediaSource,
                        name: `appsFlyer - ${mediaSource}`
                    }))}
                    PaperComponent={props => (
                        <CustomPaper {...props} width="670px" elevation={8} margin="0 0 0 150px" />
                    )}
                    fullWidth
                />
            ) : (
                <AutocompleteArrayInput
                    label="Affiliate IDs"
                    source="affiliate_ids"
                    choices={affiliates.map(affiliate => ({
                        id: affiliate.affiliate_id,
                        name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`
                    }))}
                    PaperComponent={props => (
                        <CustomPaper {...props} width="670px" elevation={8} margin="0 0 0 150px" />
                    )}
                    fullWidth
                />
            )}

            <SelectInput
                source="status"
                choices={setChoices(REGENERATE_STATUS_LABELS)}
                required
                emptyText="Choose a status"
            />

            <SelectArrayInput
                source="internal_account_ids"
                label="Internal Account IDs"
                choices={accounts.filter(({ platform }) =>
                    platform === reportTypesMapToPlatform.get(reportType)
                )}
                disabled={!reportType}
            />

            <DateInput source="default_start_date" />

            {reportType === REPORT_TYPES.META_MEDIA_APPS_FLYER_MASTER ? (
                <SelectArrayInput
                    source="event_names"
                    label="AppsFlyer Events"
                    choices={setChoices(uniqueEventNames)}
                />
            ) : (
                <SelectArrayInput
                    source="goal_names"
                    label="Goal names"
                    choices={setChoices(uniqueGoals)}
                />
            )}
        </>
    )
}

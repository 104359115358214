import { httpClient } from '../../config';

export const fetchUniqueMediaSources = async () => {
    try {
        const { json } = await httpClient('/tables/active_entities/unique_media_sources');
        return json;
    } catch (error) {
        console.error('Failed to fetch goals data:', error);
        throw error;
    }
};

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
   Edit,
   SelectInput,
   SimpleForm,
   TextInput,
   WithRecord
} from 'react-admin'
import {
   reportTypes,
   setChoices,
   transform,
} from '../../../../helpers'
import { Tooltip } from '@mui/material'
import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'
import { useUniqueGoals } from '../../../../hooks/useUniqueGoals';
import { useUniqueEventNames } from '../../../../hooks/useUniqueEventNames';
import { useUniqueMediaSources } from '../../../../hooks/useUniqueMediaSources';
import { ReportTypeDependentFieldsEdit } from './helpers/ReportTypeDependentFields.Edit';

export const ReportSettingsEdit = props => {
   const [accounts, setAccounts] = useState([])
   const [record, setRecord] = useState({})
   const [affiliates, setAffiliates] = useState([])
   const { uniqueGoals } = useUniqueGoals();
   const { uniqueEventNames } = useUniqueEventNames();
   const { uniqueMediaSources } = useUniqueMediaSources();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const [activeEntities, accessEntities] = await Promise.all([
              axios('/tables/active_entities/get_active_entities').then(data => data.data),
              httpClient(`/access/data`)
            ]);

            setAffiliates(activeEntities.affiliates)

            const fetchedAccounts = JSON.parse(accessEntities.body).accountData
              .map(({id, platform, account_name}) => ({id, platform, name: `${account_name} - ${id}`}))

            setAccounts(fetchedAccounts)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   return (
     <>
        <BackButton path="/report_settings"/>
        <span className="h4">Edit report setting</span>
        <Edit
            {...props}
            title=" "
            transform={transform('affiliate_ids', 'user_access', 'internal_account_ids', 'goal_names')}
        >
           <SimpleForm shouldUnregister={true}>
              <WithRecord render={record => (
                  <>
                     <Tooltip
                         title={
                            <div style={{ padding: '20px', fontSize: '12px' }}>
                               <div style={{ paddingBottom: '10px' }}>Master - data from platform & Tune</div>
                               <div style={{ paddingBottom: '10px' }}>Affiliate - Master without revenue</div>
                               <div>Network - Tune only</div>
                            </div>
                         }
                         placement="right-end"
                     >
                        <SelectInput
                            source="report_type"
                            choices={setChoices(reportTypes)}
                            required
                            emptyText="Choose a report type"
                            defaultValue={record.report_type}
                            disabled
                        />
                     </Tooltip>

                     <TextInput
                         source="report_name"
                         label="Report name"
                         required
                         defaultValue={record.report_name}
                         disabled
                     />

                     <ReportTypeDependentFieldsEdit
                         record={record}
                         accounts={accounts}
                         affiliates={affiliates}
                         uniqueGoals={uniqueGoals}
                         uniqueMediaSources={uniqueMediaSources}
                         uniqueEventNames={uniqueEventNames}
                     />
                  </>
              )} />
           </SimpleForm>
        </Edit>
     </>
   )
}

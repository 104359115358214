import { httpClient } from '../config';

const buildQueryParams = ({ filter, range, sort }) => {
  const filterParam = `filter=${encodeURIComponent(JSON.stringify(filter))}`;
  const rangeParam = `range=${range}`;
  const sortParam = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
  return `${filterParam}&${rangeParam}&${sortParam}`;
};

const fetchTotalCount = async (filter) => {
  try {
    const response = await httpClient(`/tables/platform_account_settings/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    return JSON.parse(response.body).count || 0;
  } catch (error) {
    console.error('Failed to fetch total count:', error);
    return 0;
  }
};

const fetchAccountData = async ({ filter, range, sort }) => {
  try {
    const queryParams = buildQueryParams({ filter, range, sort });
    const response = await httpClient(`/tables/platform_account_settings?${queryParams}`);
    const data = JSON.parse(response.body);

    return {
      account_names: data.map(({ id, account_name }) => ({ id, name: account_name, value: id })),
      account_ids: data.map(({ id, account_id }) => ({ id, name: account_id, value: id })),
    };
  } catch (error) {
    console.error('Failed to fetch account data:', error);
    return [];
  }
};

export const fetchAccounts = async (filter = {}) => {
  const totalCount = await fetchTotalCount(filter);
  if (totalCount === 0) return [];

  return await fetchAccountData({ filter, range: `[0,${totalCount - 1}]`, sort: ["account_name", "ASC"] });
};

export const filterAccountsByPlatform = ({ accounts, selectedPlatform }) => {
  return accounts
    .filter(account => account.platform === selectedPlatform)
    .map(account => ({ id: account.id, name: account.account_name }))
};

export const isAllSelectedForPlatform = ({ selectedAccounts, platformAccounts }) => {
  return selectedAccounts.filter((id) =>
    platformAccounts.some((account) => account.id === id)
  ).length === platformAccounts.length
};

export const togglePlatformSelection = ({ selectedAccounts, platformAccounts, isAllSelectedForCurrentPlatform }) => {
  const allAccountIdsRelatedToPlatform = platformAccounts.map(account => account.id);
  const otherPlatformSelections = selectedAccounts.filter(id => !allAccountIdsRelatedToPlatform.includes(id));
  return isAllSelectedForCurrentPlatform ? otherPlatformSelections : [...otherPlatformSelections, ...allAccountIdsRelatedToPlatform];
};

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
} from 'react-admin'
import { Tooltip } from '@mui/material'
import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'
import { reportTypes } from '../../../../helpers/constants'
import { transform, setChoices } from '../../../../helpers'
import { useUniqueGoals } from '../../../../hooks/useUniqueGoals';
import { useUniqueEventNames } from '../../../../hooks/useUniqueEventNames';
import { useUniqueMediaSources } from '../../../../hooks/useUniqueMediaSources';
import { ReportTypeDependentFieldsCreate } from './helpers/ReportTypeDependentFields.Create';

export const ReportSettingsCreate = props => {
    const [accounts, setAccounts] = useState([])
    const [affiliates, setAffiliates] = useState([])
    const { uniqueGoals } = useUniqueGoals();
    const { uniqueEventNames } = useUniqueEventNames();
    const { uniqueMediaSources } = useUniqueMediaSources();

    useEffect(() => {
        const doRequest = async () => {
            const [activeEntities, accessEntities] = await Promise.all([
                axios('/tables/active_entities/get_active_entities').then(data => data.data),
                httpClient('/access/data')
            ]);
            setAffiliates(activeEntities.affiliates);

            const fetchedAccounts = JSON.parse(accessEntities.body).accountData
                .map(({ id, platform, account_name }) => ({ id, platform, name: `${account_name} - ${id}` }))

            setAccounts(fetchedAccounts);
        }
        doRequest();
    }, [])

    return (
        <>
            <BackButton path="/report_settings" />
            <span className="h4">Create report setting</span>

            <Create
                {...props}
                title=" "
                transform={transform('affiliate_ids', 'internal_account_ids', 'user_access', 'goal_names')}
            >
                <SimpleForm shouldUnregister={true}>
                    <Tooltip
                        title={
                            <div style={{ padding: '20px', fontSize: '12px' }}>
                                <div style={{ paddingBottom: '10px' }}>Master - data from platform & Tune</div>
                                <div style={{ paddingBottom: '10px' }}>Affiliate - Master without revenue</div>
                                <div>Network - Tune only</div>
                            </div>
                        }
                        placement="right-end"
                    >
                        <SelectInput
                            source="report_type"
                            choices={setChoices(reportTypes)}
                            required
                            emptyText="Choose a report type"
                        />
                    </Tooltip>

                    <TextInput source="report_name" label="Report name" required />
                    <ReportTypeDependentFieldsCreate
                        accounts={accounts}
                        affiliates={affiliates}
                        uniqueGoals={uniqueGoals}
                        uniqueMediaSources={uniqueMediaSources}
                        uniqueEventNames={uniqueEventNames}
                    />
                </SimpleForm>
            </Create>
        </>
    )
}

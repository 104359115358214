import React, {
   useEffect,
   useState,
} from 'react'

import {
   Edit,
   SelectInput,
   TextInput,
   NumberInput,
   useNotify,
   TabbedForm,
   FormTab,
   CheckboxGroupInput,
   useRefresh,
} from 'react-admin'

import { BackButton } from '../../../helperComponents/BackButton'
import { setChoices } from '../../../../helpers'
import { roleChoices } from '../../../../helpers'
import { httpClient } from '../../../../config'
import { Tooltip } from '@mui/material'
import { AccountAccessTab } from './components/AccountAccessTab'

export const UserSettingsEdit = props => {
   const [accounts, setAccounts] = useState([])
   const [reports, setReports] = useState([])
   const [dbTables, setDbTables] = useState([])
   const notify = useNotify()
   const refresh = useRefresh()

   useEffect(() => {
      try {
         const doRequest = async () => {
            let response = await httpClient(`/access/data`)
            response = JSON.parse(response.body)
            setAccounts(response.accountData)
            setReports(response.reportData)
            setDbTables(response.dbTableData)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onError = () => {
      notify('Error: Something gone wrong', {type: 'warning'})
   }

   const onSuccess = () => {
      notify(`Changes saved`, {type: 'success'})
      refresh()
   }

   return (
     <>
        <BackButton path="/user_settings"/>
        <span className="h4">Edit user</span>
        <Edit {...props} title=" " mutationOptions={{onError, onSuccess}} mutationMode="pessimistic">
           <TabbedForm>
              <FormTab label="main">
                 <NumberInput source="id" label="User ID" disabled={true}/>
                 <TextInput source="username" label="User name" required={true}/>
                 <TextInput source="password" label="Password" required={true}
                            helperText="Note: Don't use hash. Just a password"
                            fullWidth={true}
                 />
                 <TextInput source="db_username" label="DB username" disabled={true}/>
                 <TextInput source="db_password" label="DB password"/>
                 <Tooltip title={
                    <div style={{padding: '20px', fontSize: '15px'}}>
                       <div style={{paddingBottom: '10px'}}>0 | Admin - All access to dashboard</div>
                       <div style={{paddingBottom: '10px'}}>
                          1 | Media Buyer - Access to Actions & Media Settings
                       </div>
                       <div style={{paddingBottom: '10px'}}>
                          2 | Affiliate - Access to Actions page except "Update tune",<br/>
                          limited by report_settings and google_account_settings,<br/>
                          can't create new account
                       </div>
                       <div style={{paddingBottom: '10px'}}>
                          3 | Content Media Manager - Access to Bridge Link System
                       </div>
                       <div style={{paddingBottom: '10px'}}>
                          4 | Affiliate Manager - Access to limited Actions & Sample Management System
                       </div>
                       <div>
                          5 | Media Manager - Access to limited Actions, Media Platform API settings, Media Data,<br/>
                          Platform Account Settings, and Report Settings
                       </div>
                    </div>
                 } placement="right-end"
                 >
                    <SelectInput source="role" label="Role" required={true}
                                 choices={setChoices(roleChoices)}
                                 emptyText="Choose a role"
                    />
                 </Tooltip>
                 <TextInput source="has_db_access" label="DB access" disabled={true}
                            defaultValue={null}/>
              </FormTab>
              <FormTab label="Account access">
                <AccountAccessTab accounts={accounts} />
              </FormTab>
              <FormTab label="Report access">
                 <CheckboxGroupInput
                   row={false}
                   label="Access to reports"
                   source="access_to_reports"
                   choices={reports.map(report => {
                      return {id: report.id, name: report.report_name}
                   })}
                 />
              </FormTab>
              <FormTab label="DB table access reports">
                 <CheckboxGroupInput
                   row={false}
                   label="Access to DB tables"
                   source="access_to_tables"
                   choices={dbTables.map(table => {
                      return {id: table.table_name, name: table.table_name}
                   })}
                 />
              </FormTab>
           </TabbedForm>
        </Edit>
     </>
   )
}
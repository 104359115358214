import { PLATFORMS, REPORT_TYPES } from '../config/constants/reportConstants';
import { USER_ROLES } from '../config/constants/authConstants';

export const version = 'v17.0.0';

export const reportTypes = [
   REPORT_TYPES.MEDIA_REPORT,
   REPORT_TYPES.MEDIA_AFFILIATE_REPORT,
   REPORT_TYPES.NETWORK_REPORT,
   REPORT_TYPES.OUTBRAIN_AD_MASTER,
   REPORT_TYPES.OUTBRAIN_SECTION_MASTER,
   REPORT_TYPES.OUTBRAIN_AD_AFFILIATE,
   REPORT_TYPES.OUTBRAIN_SECTION_AFFILIATE,
   REPORT_TYPES.BING_NO_IS_MASTER,
   REPORT_TYPES.TABOOLA_AD_MASTER,
   REPORT_TYPES.TABOOLA_SITES_MASTER,
   REPORT_TYPES.META_MEDIA_MASTER,
   REPORT_TYPES.META_MEDIA_AFFILIATE,
   REPORT_TYPES.YOUTUBE_MASTER,
   REPORT_TYPES.META_MEDIA_APPS_FLYER_MASTER
];

export const roleChoices = {
   [USER_ROLES.ADMIN]: '0 | Admin',
   [USER_ROLES.MEDIA_BUYER]: '1 | Media Buyer',
   [USER_ROLES.AFFILIATE]: '2 | Affiliate',
   [USER_ROLES.CONTENT_MEDIA_MANAGER]: '3 | Content Media Manager',
   [USER_ROLES.AFFILIATE_MANAGER]: '4 | Affiliate Manager',
   [USER_ROLES.MEDIA_MANAGER]: '5 | Media Manager',
};

export const actionNames = [
   'Download report',
   'Update tune',
   'Update report names',
   'Upload report',
   'Regenerate report',
   'Update report',
   'Regenerate report (7 days)',
   'Create Media Platform API setting',
   'Update Media Platform API setting',
   'Delete Media Platform API setting',
];

export const goalNames = ['Purchase', 'Purchase CPA', 'Check Out', 'Add To Cart'];

export const platforms = [
   {name: PLATFORMS.GOOGLE, value: PLATFORMS.GOOGLE, id: PLATFORMS.GOOGLE, conversion: true},
   {name: PLATFORMS.OUTBRAIN, value: PLATFORMS.OUTBRAIN, id: PLATFORMS.OUTBRAIN},
   {name: PLATFORMS.BING, value: PLATFORMS.BING, id: PLATFORMS.BING, conversion: true},
   {name: PLATFORMS.TABOOLA, value: PLATFORMS.TABOOLA, id: PLATFORMS.TABOOLA},
   {name: PLATFORMS.META, value: PLATFORMS.META, id: PLATFORMS.META, conversion: true},
   {name: PLATFORMS.APPS_FLYER, value: PLATFORMS.APPS_FLYER, id: PLATFORMS.APPS_FLYER, conversion: true},
];

export const searchInputWidth = '520px';

export const timezones = [
   'EST', 'GMT+2', 'GMT+8'
];

export const reportTypesMapToPlatform = new Map([
   [REPORT_TYPES.MEDIA_REPORT, PLATFORMS.GOOGLE],
   [REPORT_TYPES.MEDIA_AFFILIATE_REPORT, PLATFORMS.GOOGLE],
   [REPORT_TYPES.NETWORK_REPORT, PLATFORMS.GOOGLE],
   [REPORT_TYPES.OUTBRAIN_AD_MASTER, PLATFORMS.OUTBRAIN],
   [REPORT_TYPES.OUTBRAIN_SECTION_MASTER, PLATFORMS.OUTBRAIN],
   [REPORT_TYPES.OUTBRAIN_AD_AFFILIATE, PLATFORMS.OUTBRAIN],
   [REPORT_TYPES.OUTBRAIN_SECTION_AFFILIATE, PLATFORMS.OUTBRAIN],
   [REPORT_TYPES.BING_NO_IS_MASTER, PLATFORMS.BING],
   [REPORT_TYPES.TABOOLA_AD_MASTER, PLATFORMS.TABOOLA],
   [REPORT_TYPES.TABOOLA_SITES_MASTER, PLATFORMS.TABOOLA],
   [REPORT_TYPES.META_MEDIA_MASTER, PLATFORMS.META],
   [REPORT_TYPES.META_MEDIA_AFFILIATE, PLATFORMS.META],
   [REPORT_TYPES.META_MEDIA_APPS_FLYER_MASTER, PLATFORMS.META],
   [REPORT_TYPES.YOUTUBE_MASTER, PLATFORMS.GOOGLE]
]);

export const cronNames = [
   'actualizeReportNamesJob',
   'getPartnerTagsJob',
   'tuneLastWeekJob',
   'tuneLastMonthJob',
   'tuneLastThreeMonthsJob',
   'regenerateReportsJob',
   'updateCurrencyRateJob',
   'recheckAccessToDBJob',
   'updateViewsJob',
   'getLastTuneData',
   'getCustomerData',
   'sendMetaApiData',
   'getRegularEvents',
   'regenerateAllReports',
   'getOfferTagsJob',
   'getHistoricalEvents',
   'prepareHistoricalData',
   'getRegularClicks',
   'getHistoricalClicks',
];

export const AFFILIATE_STATUSES = {
   STATUS: 'Status',
   STATUS_ALL: 'All',
   STATUS_PENDING: 'Pending',
   STATUS_APPROVED: 'Approved',
   STATUS_REJECTED: 'Rejected',
};

export const SAMPLE_SYSTEM_LOCALES = {
   ADD_AFFILIATE: 'Add Affiliate',
   COMMENTS_TO_AFFILIATE: 'Comments to Affiliate',
};

export const downloadButtonLabel = {
   0: 'Generate',
   1: 'Generating',
   2: 'Download',
};

export const REVIEW_STATUSES = {
   SUBMITTED: 'Sample Request Submitted',
   ACCEPTED: 'Sample Request Accepted',
   REJECTED: 'Sample Request Rejected',
   ON_THE_WAY: 'Samples On The Way',
   SUBMIT_REVIEW: 'Samples Delivered',
   REVIEW_SUBMITTED: 'Product Content provided',
};
 
export const REVIEW_ACTIONS = {
   ACTION_SUBMITTED: 'Await Admin Response',
   ACTION_ACCEPTED: 'Await Shipment Tracking Link',
   ACTION_REJECTED: 'Read comments',
   ACTION_ON_THE_WAY: 'Await Samples Delivery',
   ACTION_SUBMIT_REVIEW: 'Provide Product Review or Content',
   ACTION_REVIEW_SUBMITTED: 'No Action',
};
 
export const ADVERTISER_STATUSES = {
   PENDING: 'Pending Requests',
   TRACKING_LINK_PROVIDED: 'Awaiting Affiliate Marketing Content',
   MARKETING_CONTENT_PROVIDED: 'Affiliate has provided marketing content',
};
 
export const ADVERTISER_ACTIONS = {
   SUBMIT_TRACKING_LINK: 'Submit Shipment Tracking Link',
   TRACKING_LINK_PROVIDED_ACTION: 'No Action',
   MARKETING_CONTENT_PROVIDED_ACTION: 'Review provided content',
};

export const MediaPlatformApiSettings = {
   defaultOffColumns: [
     'id',
     'platform',
     'action_source',
     'tune_purchase',
     'tune_purchase_cpa'
   ],
};

export const MetaApiData = {
   defaultOffColumns: [
      'settings_id'
   ],
};

export const tableAggregationSettings = {
  'meta_api_data': {
    defaultValue: 'pixel_id',
    options: [
      { value: 'none', label: 'None', path: 'meta_api_data' },
      { value: 'pixel_id', label: 'Pixel ID', path: 'meta_api_data/aggregated-pixel' },
     ],
  }
}
 
export const eventNames = [
   { id: 'Purchase', name: 'Purchase' },
   { id: 'PurchaseReturn', name: 'PurchaseReturn' },
   { id: 'PurchaseCPA', name: 'PurchaseCPA' },
   { id: 'InitiateCheckout', name: 'InitiateCheckout' },
   { id: 'AddToCart', name: 'AddToCart' },
   { id: 'ViewContent', name: 'ViewContent' },
];

export const copiedFieldsToRemove = {
   'media_platform_api_settings': ['id', 'fb_pixel_id', 'fb_api_key', 'fetch_status_summary_events', 'fetch_status_summary_clicks'],
};
 
import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CheckboxGroupInput } from 'react-admin';
import { ToggleButton, ToggleButtonGroup, Checkbox } from '@mui/material';
import {
  filterAccountsByPlatform,
  isAllSelectedForPlatform,
  togglePlatformSelection
} from '../../../../../utils/accountUtils';
import { PLATFORMS } from '../../../../../config/constants/reportConstants';

export const AccountAccessTab = ({ accounts }) => {
  const [selectedPlatform, setSelectedPlatform] = useState(PLATFORMS.GOOGLE);
  const [platformAccounts, setPlatformAccounts] = useState([]);
  const [isAllSelectedForCurrentPlatform, setIsAllSelectedForCurrentPlatform] = useState(false);
  
  const { setValue } = useFormContext();
  const selectedAccounts = useWatch({ name: 'access_to_accounts' }) || [];

  useEffect(() => {
    const updatedPlatformAccounts = filterAccountsByPlatform({ accounts, selectedPlatform });
    const allSelected = isAllSelectedForPlatform({ selectedAccounts, platformAccounts: updatedPlatformAccounts });

    setPlatformAccounts(updatedPlatformAccounts);
    setIsAllSelectedForCurrentPlatform(allSelected);
  }, [selectedPlatform, selectedAccounts, accounts]);

  const handleSelectAllAccounts = () => {
    const newSelectedAccounts = togglePlatformSelection({ selectedAccounts, platformAccounts, isAllSelectedForCurrentPlatform });
    setValue('access_to_accounts', newSelectedAccounts, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <>
      <p style={{ fontSize: '16px' }}>Access to Platform accounts</p>
      <ToggleButtonGroup
        color="primary"
        value={selectedPlatform}
        exclusive
        onChange={(event, newPlatform) => newPlatform && setSelectedPlatform(newPlatform)}
        aria-label="Platform selection"
        style={{ margin: '10 px 0' }}
      >
        {Object.values(PLATFORMS).map((platform) => (
          <ToggleButton
            key={platform}
            value={platform}
            style={{ padding: '10px 20px', minWidth: '120px' }}
          >
            {platform}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <CheckboxGroupInput
        row={false}
        label={
          <label 
            htmlFor="full-access-checkbox" 
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <Checkbox
              id="full-access-checkbox"
              checked={isAllSelectedForCurrentPlatform}
              onChange={handleSelectAllAccounts}
              sx={{
                '&.Mui-checked': { color: 'rgb(79, 60, 201)' },
                transform: 'scale(1.3)',
                padding: '9px 16px 9px 3px'
              }}
            />
            <span style={{ fontSize: '23px', color: 'rgba(0, 0, 0, 0.87)' }}>
              Full Access
            </span>
          </label>
        }
        source="access_to_accounts"
        choices={platformAccounts}
        optionText="name"
        optionValue="id"
      />
    </>
  );
};

import { useEffect, useState } from 'react';
import { fetchUniqueEventNames } from '../utils/loaders/eventNameApi';

export const useUniqueEventNames = () => {
    const [uniqueEventNames, setUniqueEventNames] = useState([]);

    useEffect(() => {
        const loadUniqueEventNames = async () => {
            try {
                const data = await fetchUniqueEventNames();
                setUniqueEventNames(data);
            } catch (error) {
                console.error('Error loading brands:', error);
            }
        };

        loadUniqueEventNames();
    }, []);

    return { uniqueEventNames };
};

import { useEffect, useState } from 'react';
import { fetchUniqueMediaSources } from '../utils/loaders/mediaSourceApi';

export const useUniqueMediaSources = () => {
    const [uniqueMediaSources, setUniqueMediaSources] = useState([]);

    useEffect(() => {
        const loadUniqueMediaSources = async () => {
            try {
                const data = await fetchUniqueMediaSources();
                setUniqueMediaSources(data);
            } catch (error) {
                console.error('Error loading brands:', error);
            }
        };

        loadUniqueMediaSources();
    }, []);

    return { uniqueMediaSources };
};
